import React from "react";
import {Box} from "@mui/material";
import ComeFunziona from "../components/ComeFunziona";
import Tematiche from "../components/Tematiche";
import Partecipa from "./Partecipa";
import MainSection from "../components/MainSection";
import {mondoviTheme} from "../theme/mondoviTheme";
import FAQ from "../components/FAQ";

const Home = ({sectionRefs}) => {
    return (
        <Box>
            <MainSection sectionRefs={sectionRefs}/>
            <Box mt={'100vh'} zIndex={100}>
                <Box height={'10rem'} position={'relative'}>
                    <Box position={'absolute'} height={'100%'} width={'100%'}
                         bottom={-2}
                         sx={{
                             background: `radial-gradient(100% 120px at 50% -30px, transparent 119%, ${mondoviTheme.palette.background.default} 120%) no-repeat`
                         }}>

                    </Box>
                </Box>

                <Box
                    sx={{background: mondoviTheme.palette.background.default}}
                >
                    <ComeFunziona sectionRefs={sectionRefs}/>
                    <Tematiche sectionRefs={sectionRefs}/>
                    <FAQ sectionRefs={sectionRefs}/>
                    <Partecipa sectionRefs={sectionRefs}/>
                </Box>

            </Box>
        </Box>
    )
}

export default Home
