import './App.css';
import {Box, Button, CssBaseline, Grid, Stack, ThemeProvider, Typography, useMediaQuery} from "@mui/material";
import {mondoviTheme} from "./theme/mondoviTheme";
import Header from "./components/Header";
import Home from "./pages/Home";
import {Link, Route, Routes, useParams} from "react-router-dom";
import {Footer} from "./components/Footer";
import React, {useMemo, useRef, useState} from "react";
import cover_bg from "./assets/images/sfondo_1.jpg";
import sfondoMobile from "./assets/images/sfondo_1_mobile.jpg";
import useAxios from "axios-hooks";
import {API_URL} from "./config";
import logo from "./assets/images/logo_header.png";
import {LandingZoomUser} from "./components/LandingZoomUser";
import {StreamingPage} from "./pages/StreamingPage";

const LandingUser = () => {
    const {id} = useParams()
    const matches = useMediaQuery(mondoviTheme.breakpoints.up("sm"));

    const [{data, loading, error}, fetchUsers] = useAxios(`${API_URL}/users/${id}/info`, {
        method: "GET", useCache: false
    })

    const typeFormLink = useMemo(() => {
        if(error)
            return `https://rmou3qne4s8.typeform.com/to/QaPM7Iow#hash=${id}`;
        else if(data) {
            console.log("data:",data)
            return `https://rmou3qne4s8.typeform.com/to/QaPM7Iow#hash=${id}&email=${data.email}&nome=${data.name}`;
        }
    },[data, error])


    //users/:id/name

    return (
        <Stack pb={8} px={4}
               sx={{
                   width: '100%',
                   height: '100vh',
                   //background: 'linear-gradient(117deg, rgba(71,177,91,1) 0%, rgba(33,106,212,1) 45%, rgba(33,106,212,1) 50%, rgba(30,84,127,1) 100%)',
                   background: 'linear-gradient(129deg, rgba(71,177,91,1) 12%, rgba(33,106,212,1) 41%, rgba(33,106,212,1) 54%, rgba(30,84,127,1) 94%)',
                   position: 'relative',
                   backgroundImage: {
                       lg: `url(${cover_bg})`,
                       md: `url(${cover_bg})`,
                       sm: `url(${cover_bg})`,
                       xs: `url(${sfondoMobile})`
                   },
                   backgroundAttachment: 'fixed',
                   backgroundSize: 'cover'
               }} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={12} sm={10} md={10} lg={6} xl={5}>
                {matches ? <p style={{
                        paddingInline: 4,
                        fontSize: 'min(4.8vw, 1.485rem)',
                        //fontWeight: 'bold',
                        maxWidth: '670px',
                        marginTop: '1rem',
                        textAlign: 'left'
                    }}>
                        22 APRILE, DALLE 9.30 ALLE 12.30
                    </p>
                    : <><Typography variant={'h5'} style={{
                        paddingTop: 4,
                        fontSize: 'min(6.5vw, 1.685rem)',
                        maxWidth: '670px',
                        margin: '0',
                        textAlign: 'left'
                    }}>
                        22 APRILE
                    </Typography>
                        <Typography variant={'h5'} style={{
                            fontSize: 'min(6.5vw, 1.685rem)',
                            maxWidth: '670px',
                            marginBottom: '1.5rem',
                            textAlign: 'left'
                        }}>
                            9.30 - 12.30
                        </Typography>
                    </>}
                <Typography
                    variant={"h2"}
                    maxWidth={'550px'}
                    sx={{
                        fontSize: matches ? '3.5rem' : '3rem',
                        lineHeight: matches ? '4.188rem' : '3rem',
                        fontWeight: 'bold',
                        marginBottom: '1.4rem',
                        textAlign: 'left',
                        maxWidth: matches ? null : '250px'
                    }}>
                    {!data?.submittedTypeform ?
                        "Completa la tua registrazione!"
                        : "Grazie per aver completato il form!"}
                </Typography>
                <p style={{
                    paddingInline: 4,
                    fontSize: 'min(4.8vw, 1.485rem)',
                    fontWeight: 400,
                    color: matches ? mondoviTheme.palette.text.secondary : mondoviTheme.palette.text.primary,
                    maxWidth: '670px',
                    marginTop: 0,
                    textAlign: 'left'
                }}>
                    {!data?.submittedTypeform ?
                        "Per completare la tua candidatura ti chiediamo di rispondere al form che troverai cliccando il pulsante qui sotto. \n" +
                        "Ti ruberà solo pochi minuti!"
                        : "Ti ricordiamo che l’evento è gratuito e si terrà online sabato 22 aprile, dalle 9.30 alle 12.30, per accedere il giorno dell'evento, clicca qui sotto."}
                </p>
                {data?.submittedTypeform ?
                    <Button variant={'contained'}
                            href={"/event/"+id}
                    //target={'_blank'}
                >
                    Vai alla pagina di accesso
                </Button> : null}
                {matches ? <Stack direction={'row'}>
                        <Button variant={'contained'}
                                sx={{
                                    display: data?.submittedTypeform ? 'none' : '',
                                }}
                                href={typeFormLink}
                                //target={'_blank'}
                        >
                            Vai al form
                        </Button>
                    </Stack>
                    : <Button variant={'contained'}
                              href={typeFormLink}
                              //target={'_blank'}
                              sx={{
                                  background: '#47b15b',
                                  mt: '8px',
                                  height: '55px',
                                  width: '80%',
                                  display: data?.submittedTypeform ? 'none' : '',
                                  fontSize: '1.5rem',
                                  borderRadius: '3rem'
                              }}
                    >
                        Vai al form
                    </Button>}
            </Grid>
        </Stack>
    );
}

function App() {
    const matches = useMediaQuery(mondoviTheme.breakpoints.up("sm"));
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    return (
        <ThemeProvider theme={mondoviTheme}>
            <CssBaseline/>
            <div className="App">
                <Routes>

                    <Route path={'/'} element={<>
                        {matches && <Header sectionRefs={sectionRefs}/>}
                        <Home sectionRefs={sectionRefs}/>
                    </>}/>
                    <Route path={'/form/:id'} element={
                        <>
                            <Header/>
                            <LandingUser/>
                        </>
                    }/>
                    <Route path={'/event/:id'} element={<LandingZoomUser scuole={false}/>}/>
                    <Route path={'/scuole'} element={<LandingZoomUser scuole={true}/>}/>
                    <Route path={'/event'} element={<LandingZoomUser scuole={false}/>}/>
                    <Route path={'/streaming'} element={<StreamingPage/>}/>
                </Routes>
                <Footer/>
            </div>
        </ThemeProvider>
    );
}

export default App;
