import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, CircularProgress, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import {mondoviTheme} from "../theme/mondoviTheme";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import React, {useEffect, useMemo} from "react";
import cover_bg from "../assets/images/sfondo_1.jpg";
import sfondoMobile from "../assets/images/sfondo_1_mobile.jpg";
import logo from "../assets/images/logo_header.png";
import {FormZoom} from "./FormZoom";

export const LandingZoomUser = ({scuole}) => {
    const {id} = useParams()
    const matches = useMediaQuery(mondoviTheme.breakpoints.up("sm"));
    const navigate = useNavigate()

    const [{data, loading, error}, fetchUsers] = useAxios(`${API_URL}/users/${id}/info`, {
        method: "GET", useCache: false
    })

    const [zoomLink, setZoomLink] = React.useState('')

    useEffect(() => {
        if (data) {
            setZoomLink(data.zoomUrl.join_url)
        } else if (error && !loading) {
            navigate(scuole ? '/scuole' : '/event', {replace: true})
        }
    }, [data, error, loading])

    return (
        <Stack pb={8} px={{xs: 3, sm: 4}}
               sx={{
                   width: '100%',
                   height: {xs: 'max(80rem,100vh)', sm: '100vh'},
                   background: 'linear-gradient(129deg, rgba(71,177,91,1) 12%, rgba(33,106,212,1) 41%, rgba(33,106,212,1) 54%, rgba(30,84,127,1) 94%)',
                   position: 'relative',
                   backgroundImage: {
                       sm: `url(${cover_bg})`,
                       xs: `url(${sfondoMobile})`
                   },
                   backgroundAttachment: 'fixed',
                   backgroundSize: 'cover'
               }} alignItems={'center'} justifyContent={'center'}>
            <Box mt={3} position={'absolute'} top={10} left={30}>
                <img style={{maxWidth: '170px', maxHeight: '65px'}} src={logo}/>
            </Box>
            {
                loading ?
                    <Box>
                        <CircularProgress/>
                    </Box>
                    :
                    <Grid item xs={12} sm={12} md={10} lg={6} xl={5}>
                        {id ? <Box>
                                <Typography
                                    variant={"h3"}
                                    sx={{
                                        paddingTop: 4,
                                        fontSize: matches ? '2.5rem' : '2rem',
                                        lineHeight: matches ? '4.188rem' : '3rem',
                                        fontWeight: 'bold',
                                        marginBottom: '1.4rem',
                                        textAlign: 'left',
                                        maxWidth: matches ? '570px' : '100%'
                                    }}>
                                    {"Tramite il pulsante che trovi qui sotto potrai accedere all'evento sabato 22 aprile alle 9:30"}
                                </Typography>
                                <Button variant={'contained'}
                                        href={zoomLink}
                                        target={'_blank'}
                                        sx={{
                                            mt: {xs: '1rem', sm: 0},
                                            height: {xs: '55px', sm: 'auto'},
                                            fontSize: {xs: '1.25rem', sm: 'auto'},
                                            borderRadius: '3rem'
                                        }}
                                >
                                    Vai alla chiamata Zoom
                                </Button>
                            </Box>
                            :
                            <FormZoom scuole={scuole}/>
                        }
                    </Grid>
            }
            <Box sx={{pt: 6}}>
                <Typography variant={'h5'}>
                    Se hai problemi contatta il numero 3484495313 oppure 3396146989
                </Typography>
            </Box>
        </Stack>
    );
}
