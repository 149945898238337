import {createTheme} from "@mui/material";

export const mondoviTheme = createTheme({
    typography: {
        fontFamily: ['Neue Machina', 'Epilogue'],
        h1: {
            fontFamily: 'Neue Machina'
        },
        h2: {
            fontFamily: 'Neue Machina'
        },
        h3: {
            fontFamily: 'Neue Machina'
        },
        h4: {
            fontFamily: 'Neue Machina'
        },
    },

    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background:'transparent',
                    boxShadow:'none',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "rgba(255,255,255,0.8)",
                    "& input": {
                        textAlign: "center",
                        fontWeight: 'bold',
                        fontSize: '1.25rem'
                    }
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    background: '#1A5174',
                    borderRadius: '2rem',
                    boxShadow: 'none',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: '#1A5174',
                    borderRadius: '2rem',
                    boxShadow: 'none',
                    '& fieldset': {
                        borderRadius: `2rem`,
                    },
                }
            }
        },
        MuiCard: {
            variants: [
                {
                    props: {variant: 'custom-card'},
                    style: {
                        background: '#252a33',
                        border: '3px solid #323946',
                        boxShadow: 'inset 0em 0px 18px 1px rgba(106,120,147,0.1)',
                        '&:hover': {
                            background:'#2f3541',
                            color: 'white',
                            boxShadow: '0em 0px 6px 0px rgba(248,36,59,0.3)',
                        }
                    }
                }
            ]
        },
        MuiAccordion: {
            variants: [
                {
                    props: {variant: 'faq'},
                    style: {
                        width: '100%',
                        //minHeight: '60px',
                        background: '#DFE2E6',
                        color: '#191F4E',
                        borderRadius: "1.7rem",
                        boxShadow: 'inset 0em 0px 18px 1px rgba(106,120,147,0.1)',
                        '&:first-of-type': {
                            borderRadius: "1.7rem",
                        },
                        '&:last-of-type': {
                            borderRadius: "1.7rem",
                        },
                        '&:before': {
                            display: 'none',
                        }
                    }
                }
            ]
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    borderRadius:'2rem',
                },
                gradient: {
                    background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #4C4C7E',
                    transition:'0.5s ease',
                    '&:hover': {
                        background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 20%, rgba(17, 17, 44,1) 100%) 100% / 200%',
                        boxShadow:'0px 0px 13px 0px rgba(76,76,126,0.72)'
                    },
                },
                swipe: {
                    background: 'linear-gradient(0deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #393462',
                    transition:'0.5s ease',
                    boxShadow:'0px 0px 20px 0px rgba(0,0,0,0.46)'
                },
                demo: {
                    background: '#252a33',
                    border: '2px solid #323946',
                    borderRadius: "2rem",
                    boxShadow: 'inset 0em 0px 18px 1px rgba(106,120,147,0.1)',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius:'2rem',
                    fontWeight:'400',
                    textTransform:'none',
                    fontSize:'1.2rem',
                    padding:'6px 40px'
                },
                contained: {
                    backgroundColor: '#1E547F',
                    color: "white",
                    paddingBlock: 10,
                    //fontWeight: 'bold',
                    boxShadow: '0px 0px 12px 1px rgba(0,0,0,0.30)'
                },
                outlined: {
                    border: '0.5px solid #adadad',
                    background: 'transparent',
                    '&:hover': {
                        background: 'transparent',
                        border: '1px solid #F3C1FF',
                        boxShadow: '0em 0px 6px 0px #F3C1FF',
                    }
                },
                outlinedSelected: {
                    background: 'transparent',
                    border: '1px solid #F3C1FF',
                    boxShadow: '0em 0px 6px 0px #F3C1FF',
                },
                menu: {
                    color: 'rgba(255,255,255,0.9)',
                    '&:hover': {
                        background:'transparent',
                        color: '#F3C1FF'
                    }
                }
            }
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#F3C1FF',
        },
        secondary: {
            main: '#323946',
        },
        background: {
            paper: 'white',
            secondary: '#0E1240',
            default: '#181E4B'
        },
        accent: {
            main: '#47B15B',
        },
        text: {
            primary: '#ffffff',
            secondary: '#1E547F',
            darker: '#191F4E'
        },
    },
});
