import React, {useState} from "react";
//import {InlineWidget} from "react-calendly";
import {
    Alert,
    AlertTitle,
    Button, CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {ArrowForwardIos} from "@mui/icons-material";
import {API_URL} from "../config";
import useAxios from "axios-hooks";
import {mondoviTheme} from "../theme/mondoviTheme";

const options = [
    {value: "studio", label: "Studio o studiavo a Mondovì"},
    {value: "lavoro", label: "Lavoro o lavoravo a Mondovì"},
    {value: "vivo", label: "Vivo o vivevo a Mondovì"},
    {value: "frequento", label: "Frequento molto Mondovì"},
    {value: "nessunaSopra", label: "Nessuna delle risposte sopra"},
    {value: "nessunRapporto", label: "Non ho nessun rapporto con Mondovì"},
]

const Partecipa = ({sectionRefs}) => {
    const [dati, setDati] = useState({
        nome: '',
        cognome: '',
        email: '',
        annoNascita: '',
        rapportoMondovi: '',
    })
    const [{data, loading, error}, registerUser] = useAxios({},
        {manual: true}
    )

    const handleSendEmail = async () => {
        await registerUser({
            data: {
                nome: dati.nome,
                cognome: dati.cognome,
                email: dati.email.trim().toLowerCase(),
                annoNascita: dati.annoNascita,
                rapportoMondovi: dati.rapportoMondovi,
            },
            url: `${API_URL}/register`, // TODO: url
            method: "POST"
        })
    }

    const handleChange = (event, field) => {
        setDati({
            ...dati,
            [field]: event.target.value
        })
    }

    function isValid() {
        return (!dati.nome || !dati.cognome || !dati.email
            || !dati.annoNascita || (!regex.test(dati.annoNascita)) || !dati.rapportoMondovi);
    }

    const regex = /^[0-9]+$/

    return (
        <Stack id={'iscriviti'} ref={sectionRefs[4]} alignItems={'center'} width={'100%'}>
            {/*<InlineWidget styles={{height:'100%'}} url="https://calendly.com/2nd-stage/30min"/>*/}
            <Stack py={9} px={{xs: 1, sm: 7}} alignItems={'center'}>
                <Typography textAlign={'center'} variant={'h3'} fontWeight={'bold'}>
                    Il confronto è aperto.
                </Typography>
                <Typography gutterBottom textAlign={'center'} variant={'h3'} fontWeight={'bold'}>
                    Partecipa.
                </Typography>
                <Typography variant={'h6'} textAlign={'center'}
                            fontWeight={300} gutterBottom
                            sx={{maxWidth: '350px', paddingInline: 2}}>
                    Compila il form e invia la tua richiesta di partecipazione. Ti contatteremo via email
                    immediatamente.
                </Typography>
                <Typography variant={'h6'} textAlign={'center'}
                            fontWeight={300} gutterBottom
                            color={mondoviTheme.palette.accent.main}
                            sx={{maxWidth: '350px', paddingInline: 2, marginTop: 2}}>
                    ISCRIVITI SUBITO.
                </Typography>
                <Stack mt={6} px={1} spacing={1.5} width={'100%'} width={'360px'}>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={'Nome'}
                               required
                               value={dati.nome}
                               onChange={(event) => handleChange(event, 'nome')}
                               type={'text'}/>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={'Cogome'}
                               required
                               value={dati.cognome}
                               onChange={(event) => handleChange(event, 'cognome')}
                               type={'text'}/>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={'E-mail'}
                               required
                               value={dati.email}
                               onChange={(event) => handleChange(event, 'email')}
                               type={'email'}/>
                    <TextField fullWidth variant={'outlined'}
                               placeholder={'Anno di nascita'}
                               required
                               value={dati.annoNascita}
                               onChange={(event) => handleChange(event, 'annoNascita')}
                               error={dati.annoNascita && !regex.test(dati.annoNascita)}
                               helperText={(dati.annoNascita && !regex.test(dati.annoNascita)) ? "Inserisci solo l'anno in cui sei nato/a" : ""}
                               type={'number'}/>
                    <FormControl fullWidth>
                        <InputLabel
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                margin: 'auto',
                                color: 'rgba(255,255,255,0.5)'
                            }}
                        >
                            Il tuo rapporto con Mondovì
                        </InputLabel>
                        <Select
                            label={"Il tuo rapporto con Mondovì"}
                            variant={'outlined'}
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                            }}
                            inputProps={{
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'white'
                            }}
                            value={dati.rapportoMondovi}
                            onChange={(event) => handleChange(event, 'rapportoMondovi')}
                        >
                            {(options).map(v =>
                                <MenuItem sx={{color: mondoviTheme.palette.text.secondary, fontSize: '1.25rem'}}
                                          value={v.value} key={v.value}>
                                    {v.label}
                                </MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button variant={'contained'}
                            type={"submit"}
                            disabled={loading || isValid()}
                            onClick={handleSendEmail}
                            endIcon={<ArrowForwardIos/>}
                            sx={{paddingInline: 3, fontSize: '1.5rem', background: mondoviTheme.palette.accent.main}}
                    >
                        AVANTI
                    </Button>
                    {(data || error) &&
                        <Alert severity={error ? 'error' : 'success'}
                               sx={{background: error ? 'rgba(255,141,141,0.32)' : 'rgba(179,255,141,0.32)'
                        }}>
                            <AlertTitle>{error ? "Errore" : "Grazie, candidatura inviata!"}</AlertTitle>
                            <AlertTitle>{error ? "" : " Ti arriverà a breve un'email con ulteriori indicazioni per partecipare. " +
                                "Ti consigliamo di controllare anche la casella dello spam."}</AlertTitle>
                            {error ? error?.response?.data?.error : ''}
                        </Alert>}
                    {loading &&
                        <Stack alignItems={'center'}>
                            <CircularProgress/>
                        </Stack>}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Partecipa;
