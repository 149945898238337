import React from "react";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import {mondoviTheme} from "../theme/mondoviTheme";

const cards = [
    {
        id: "gruppo1",
        title: "Un brainstorming collettivo per dare forma a idee e proposte",
        body: "Hai tra i 16 e i 35 anni? È l’ora di condividere il tuo punto di vista! Partecipa all’evento virtuale promosso dal comune di Mondovì, un’occasione unica di fare rete, conoscere persone e scambiare spunti e ispirazioni.",
        img: 'gruppo1.png'
    },
    {
        id: "gruppo2",
        title: "2 temi, 15 tavoli di confronto",
        body: "I temi saranno introdotti da ospiti d’eccezione per stimolare la conversazione. I tavoli virtuali, invece, saranno moderati da persone esperte pronte a rendere l’esperienza ancora più creativa e coinvolgente. Che fai, partecipi?",
        img: 'gruppo2.png'
    },
    {
        id: "gruppo3",
        title: "Un unico risultato finale",
        body: "Al termine dell'evento le idee di tutti i partecipanti saranno raccolte in un unico documento di sintesi che verrà consegnato alle istituzioni. Le idee di centinaia di giovani Monregalesi saranno di base per costruire le nuove azioni promosse dal Comune di Mondovì.",
        img: 'gruppo3.png'
    }
]

function Servizio({content, alternate, mobile}) {
    const mdXl = useMediaQuery(mondoviTheme.breakpoints.between("md", "xl"));
    return (
        <Box id={content.id} px={!mobile ? 6 : 0} pb={3} mb={2} display={'flex'} justifyContent={'left'}>
            {
                !mobile && alternate ?
                    <Stack direction={'row'} display={'flex'} flexWrap={mobile ? 'wrap' : 'inherit'}
                           justifyContent={{xs: 'start', sm: 'center'}} alignItems={'center'}>
                        <Box px={2} maxWidth={{xl: '650px', lg: '550px', md: '400px', sm: '350px'}}>
                            <Typography gutterBottom variant={mdXl ? 'h3' : 'h4'} fontWeight={'bold'}
                            >
                                {content.title}
                            </Typography>
                            <Typography variant={'h6'} fontWeight={'lighter'}
                                sx={{lineHeight: '1.8rem'}}>
                                {content.body}
                            </Typography>
                        </Box>
                        <img style={{paddingInline: 0, maxWidth: !mobile ? '500px' : '400px'}}
                             src={require('../assets/images/' + content.img)}
                        />
                    </Stack>
                    :
                    <Stack direction={'row'} display={'flex'} flexWrap={mobile ? 'wrap' : 'inherit'}
                           justifyContent={{xs: 'start', sm: 'space-evenly'}} alignItems={'center'}>
                        <img style={{paddingInline: 0, maxWidth: !mobile ? '500px' : '400px'}}
                             src={require('../assets/images/' + content.img)}
                        />
                        <Box maxWidth={{xl: '650px', lg: '550px', md: '400px', sm: '350px', xs: '350px'}}>
                            <Typography gutterBottom variant={mdXl ? 'h3' : 'h4'} fontWeight={'bold'}>
                                {content.title}
                            </Typography>
                            <Typography variant={'h6'} fontWeight={'lighter'}
                                sx={{lineHeight: '1.8rem'}}
                            >
                                {content.body}
                            </Typography>
                        </Box>
                    </Stack>
            }
        </Box>
    );
}

const ComeFunziona = ({sectionRefs}) => {
    const mobile = useMediaQuery(mondoviTheme.breakpoints.between('xs', 'lg'))

    return (
        <Stack id={'comeFunziona'} ref={sectionRefs[1]} pt={4} pb={8} justifyContent={'center'}>
            <Stack direction={{lg: 'row', md: 'row', sm: 'row', xs: 'column'}}
                   flexWrap={'wrap'}
                   justifyContent={'center'}
                   mx={'inherit'}
                   px={mobile ? 2 : 'inherit'}
            >
                {
                    cards.map((c, i) => (
                        <Servizio key={c.id} content={c} alternate={i % 2 === 0} mobile={mobile}/>
                    ))
                }
            </Stack>
        </Stack>
    );
}

export default ComeFunziona
