import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    alpha,
    Box,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {mondoviTheme} from "../theme/mondoviTheme";
import React from "react";
import {slice, upperFirst} from "lodash";

function FAQAccordion({faq, mobile}) {
    return (
        <Accordion variant={'faq'} sx={{marginBottom: '8px', marginLeft: '4px', marginRight: '4px',
            width: mobile ? '100%' : '100%', maxWidth: '450px'}}>
            <AccordionSummary
                expandIcon={<ExpandMore sx={{color: mondoviTheme.palette.text.darker}}/>}
                aria-controls="content"
                id={faq.question}
            >
                <Typography variant={'h6'}>
                    {upperFirst(faq.question.toLowerCase())}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant={"subtitle1"} color={alpha(mondoviTheme.palette.text.darker, 0.70)}>
                    {faq.answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}

const FAQ = ({sectionRefs}) => {
    const mobile = useMediaQuery(mondoviTheme.breakpoints.between("xs", "sm"));

    return (
        <Stack id={'faq'} ref={sectionRefs[3]} spacing={3} mt={6} alignItems={'center'}>
            {mobile ? <div>
                    <Typography variant={"h4"} fontWeight={'bold'} textAlign={'center'}>
                        Tutto chiaro?
                    </Typography>
                    <Typography variant={"h4"} fontWeight={'bold'} textAlign={'center'}>
                        Ecco alcune risposte.
                    </Typography>
                </div>
                : <Typography variant={"h4"} fontWeight={'bold'} textAlign={'baseline'}>
                    Tutto chiaro? Ecco alcune risposte.
                </Typography>}
            <Stack p={2} direction={'row'} justifyContent={'center'} flexWrap={'wrap'}>
                <Stack alignItems={'baseline'}>
                    {slice(faqs, 0,8).map((faq, i) => (
                        <FAQAccordion key={i} faq={faq} mobile={mobile}/>
                    ))}
                </Stack>
                <Stack alignItems={'baseline'}>
                    {slice(faqs, 8,16).map((faq, i) => (
                        <FAQAccordion key={i} faq={faq} mobile={mobile}/>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default FAQ

const faqs = [
    {
        question: "DOVE SI SVOLGE L’EVENTO?",
        answer: "L’evento è digitale, quindi puoi partecipare da ovunque ti trovi.",
    },
    {
        question: "L’EVENTO È A PAGAMENTO?",
        answer: "No. Mondovì: Domani + Giovani è un evento totalmente gratuito.",
    },
    {
        question: "QUANTO DURA L’EVENTO?",
        answer: "L’agorà digitale dura 3 ore. Comincia alle 9:30 e termina alle 12:30. Seguirla tutta è fondamentale.",
    },
    {
        question: "POSSO PARTECIPARE SOLO PER UNA PARTE DELLA MATTINATA?",
        answer: "Purtroppo no, è fondamentale garantire la presenza per l’intera mattinata, dall’inizio alla fine dei lavori.",
    },
    {
        question: "IN QUANTI POSSIAMO PARTECIPARE?",
        answer: "Il massimo sono 150 persone.",
    },
    {
        question: "CHI PUÒ PARTECIPARE?",
        answer: "Tutte le persone tra i 16 e i 35 anni che vivono, hanno vissuto o studiato o lavorato a Mondovì. Fino a esaurimento posti.",
    },
    {
        question: "DEVO AVERE COMPETENZE PARTICOLARI?",
        answer: "No, l’unico requisito è la voglia di mettersi in gioco, confrontarsi e immaginare insieme il futuro di Mondovì, per progettare un territorio a misura di Giovani. Devi però essere residente, domiciliato o aver vissuto, studiato o lavorato a Mondovì.",
    },
    {
        question: "COME MI ISCRIVO?",
        answer: "Compila il form che trovi nella home di questo sito per inviare la tua richiesta. Riceverai nei giorni successivi un'email con un questionario.",
    },
    {
        question: "COSA CHIEDE IL QUESTIONARIO?",
        answer: "Dati socio-demografici (come genere, classe di età, titolo di studio, condizione lavorativa, regione di residenza), utili a completare il tuo profilo facilitando il processo di selezione e creazione dei tavoli di lavoro.",
    },
    {
        question: "COME FUNZIONA L’EVENTO?",
        answer: "Parteciperai a 2 sessioni di lavoro. Iniziano tutte con un talk principale (nella Stanza Centrale) e proseguono con la suddivisione in gruppi di lavoro più piccoli (ai Tavoli Digitali) dove potrai esprimere le tue idee e ascoltare quelle degli altri.",
    },
    {
        question: "COSA DEVO AVERE?",
        answer: "Ti serve un computer, delle cuffie e una buona connessione a Internet.",
    },
    {
        question: "CHE SOFTWARE USEREMO?",
        answer: "L’evento è basato su ZOOM scarica l’app gratuita dal sito ufficiale.",
    },
    {
        question: "POSSO PARTECIPARE SENZA WEBCAM?",
        answer: "Per il confronto al tavolo virtuale è fondamentale conoscersi , vedersi vis-à-vis. Potrai scegliere quando attivare o disattivare la tua videocamera, ma devi averne una a disposizione.",
    },
    {
        question: "POSSO SCEGLIERE I COMPONENTI DEL MIO TAVOLO VIRTUALE?",
        answer: "I Tavoli Virtuali, subito dopo il check-in, vengono assegnati sulla base di un software che smista i partecipanti nel modo più eterogeneo possibile così da arricchire la discussione ai tavoli. Anche per questo, è fondamentale che tu interagisca con persone che non conosci.",
    },
    {
        question: "L'EVENTO È IN ITALIANO?",
        answer: "Sì, l’evento è interamente in Italiano.",
    },
    {
        question: "QUALI RISULTATI SI VORREBBERO DALL’EVENTO?",
        answer: "Tutte le idee e le proposte che emergeranno nei vari Tavoli Virtuali saranno raccolte dal moderatore di ciascun gruppo e poi racchiuse in un documento di sintesi finale. Questo servirà per dare delle linee guida alle istituzioni monregalesi.",
    }
]