import {Box, Paper, Stack, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {mondoviTheme} from "../theme/mondoviTheme";
import {useTranslation} from "react-i18next";
import cover_bg from "../assets/images/sfondo_2.jpg";
import cover_bg_mobile from "../assets/images/sfondo_2_mobile.jpg";

const Tematiche = ({sectionRefs}) => {
    const {t} = useTranslation()
    const mobile = useMediaQuery(mondoviTheme.breakpoints.between("xs", "sm"));

    return (
        <Box  id={'tematiche'} ref={sectionRefs[2]}
              px={4} position={"relative"} display={'flex'} justifyContent={'center'}
              sx={{
                 width: '100%',
                 height: '100%',
                 //background: 'linear-gradient(117deg, rgba(71,177,91,1) 0%, rgba(33,106,212,1) 45%, rgba(33,106,212,1) 50%, rgba(30,84,127,1) 100%)',
                 background: 'linear-gradient(129deg, rgba(71,177,91,1) 12%, rgba(33,106,212,1) 41%, rgba(33,106,212,1) 54%, rgba(30,84,127,1) 94%)',
                 backgroundImage: {
                     lg: `url(${cover_bg})`,
                     md: `url(${cover_bg})`,
                     sm: `url(${cover_bg})`,
                     xs: `url(${cover_bg_mobile})`
                 },
                 backgroundSize: mobile ? 'auto' : 'cover',
                 backgroundPositionY: 'bottom',
                 backgroundPositionX: mobile ? 'left' : 'bottom',
                 backgroundRepeat: "no-repeat"
             }}
        >
            <Stack py={!mobile ? 8 : 4} alignItems={'center'}
                   color={mondoviTheme.palette.text.darker}
            >
                <Typography gutterBottom textAlign={'center'} variant={'h4'} fontWeight={'bold'}>
                    Di cosa parleremo?
                </Typography>
                <Typography variant={'h6'} textAlign={'center'} fontWeight={300} sx={{maxWidth: '350px'}}>
                    Una consultazione giovanile di quattro ore per chiedere agli under35 del Comune di Mondovì idee e soluzioni per migliorare il dialogo tra giovani ed istituzioni.
                </Typography>
                <Stack mt={2} p={3} direction={!mobile ? 'row' : 'column'} spacing={2} color={mondoviTheme.palette.text.darker}
                       maxWidth={mobile ? '80%' : '70%'}>
                    <Box p={mobile ? 2 : 4} component={Paper} sx={{background: 'white'}}>
                        {!mobile && <div className={"triangoloEq"}></div>}
                        <img style={{
                            maxWidth: '400px'
                        }}
                             src={require('../assets/images/tema1.png')}/>
                        <Typography variant={'h4'} fontWeight={'bold'} color={mondoviTheme.palette.text.darker}>
                            Connessioni
                        </Typography>
                        <Typography gutterBottom variant={'h6'} fontWeight={'bold'} fontFamily={'Epilogue'} color={mondoviTheme.palette.text.darker}>
                            Cosa ci serve per fare comunità
                        </Typography>
                        <Typography color={mondoviTheme.palette.text.darker}>
                            Connettere luoghi, persone e generazioni: un tema fondamentale che metteremo al centro per capire come stimolare e sostenere la partecipazione alla vita pubblica e alle iniziative del nostro territorio. Come creare una mobilità su misura delle esigenze e degli interessi dei più giovani? A che punto siamo con la trasformazione digitale? Cosa ci serve per creare spazi fisici e virtuali di ascolto, confronto e divertimento? Partiamo dalle tue necessità e dalle tue idee per trovare risposta.
                        </Typography>
                    </Box>
                    <Box p={mobile ? 2 : 4} component={Paper} sx={{background: 'white'}}>
                        {!mobile && <div className={"triangoloEq2"}></div>}
                        <img style={{
                            maxWidth: '400px'
                        }}
                             src={require('../assets/images/tema2.png')}/>
                        <Typography variant={'h4'} fontWeight={'bold'} color={mondoviTheme.palette.text.darker}>
                            New Skill
                        </Typography>
                        <Typography gutterBottom variant={'h6'} fontWeight={'bold'} fontFamily={'Epilogue'} color={mondoviTheme.palette.text.darker}>
                            Nuove capacità. Nuove opportunità
                        </Typography>
                        <Typography color={mondoviTheme.palette.text.darker}>
                            Viviamo in una realtà sempre più veloce, interconnessa, globale e tecnologica. Caratterizzata dalla contaminazione tra diversi settori. Per questo è essenziale capire come innovare l’offerta formativa e incoraggiare un approccio interdisciplinare. Quali sono le nuove competenze che richiede il mondo del lavoro? In che modo possiamo rendere più efficace l’orientamento universitario? Come promuovere l’importanza dell’educazione al benessere? Pensiamoci insieme.
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}

export default Tematiche