import React from "react";
import cover_bg from "../assets/images/sfondo_1.jpg";
import sfondoMobile from "../assets/images/sfondo_1_mobile.jpg";
import {Box, Button, Container, Stack, Typography} from "@mui/material";
import logo from "../assets/images/logo_header.png";

export const StreamingPage = () => {
    return (
        <Stack pb={8} px={{xs: 3, sm: 4}}
               sx={{
                   width: '100%',
                   height: {xs: 'max(80rem,100vh)', sm: 'max(74rem, 100vh)'},
                   background: 'linear-gradient(129deg, rgba(71,177,91,1) 12%, rgba(33,106,212,1) 41%, rgba(33,106,212,1) 54%, rgba(30,84,127,1) 94%)',
                   position: 'relative',
                   backgroundImage: {
                       sm: `url(${cover_bg})`,
                       xs: `url(${sfondoMobile})`
                   },
                   backgroundAttachment: 'fixed',
                   backgroundSize: 'cover'
               }} alignItems={'center'} justifyContent={'center'}>
            <Box mt={3} position={'absolute'} top={10} left={30}>
                <img style={{maxWidth: '170px', maxHeight: '65px'}} src={logo}/>
            </Box>
            <Container maxWidth={'lg'} sx={{height: 'max(100vh, 68rem)', display: 'flex', alignItems: 'center'}}>
                <Box sx={{width: '100%'}}>
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src={"https://vimeo.com/event/3334208/embed/0d4ccf73a1"}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                        ></iframe>
                    </div>
                </Box>
            </Container>
        </Stack>
    )
}
