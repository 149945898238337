import React from "react";
import {Box, Button, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import {mondoviTheme} from "../theme/mondoviTheme";
import useAxios from "axios-hooks";
import {API_URL} from "../config";

const campiScuole = [
    {
        id:'istituto',
        label: 'Istituto',
        type: 'text'
    },
    {
        id:'classe',
        label: 'Classe',
        type: 'text'
    },
    {
        id:'numeroAlunni',
        label: 'Numero alunni',
        type: 'number'
    },
    {
        id:'numeroTavoli',
        label: 'Numero tavoli',
        type: 'number'
    }
]

const campiPersone = [
    {
        id:'nome',
        label: 'Nome',
        type: 'text'
    },
    {
        id:'cognome',
        label: 'Cognome',
        type: 'text'
    },
    {
        id:'email',
        label: 'Email',
        type: 'email'
    }
]

export const FormZoom = ({scuole}) => {
    const [dati, setDati] = React.useState({})
    const [{data, loading, error}, registerUser] = useAxios({},
        {manual: true}
    )

    const sendData = async (event) => {
        event.preventDefault()
        await registerUser({
            data: {...dati, scuole},
            url: `${API_URL}/zoom`, // TODO: url
            method: "POST"
        })
    }

    const handleCompileDati = (event) => {
        setDati({
            ...dati,
            [event.target.name]: event.target.value
        })
    }

    return (
        <Box>
            {!data ? <Box>
                <Typography
                    variant={"h3"}
                    sx={{
                        paddingTop: 4,
                        fontSize: {sm: '2.5rem', xs: '2rem'},
                        lineHeight: {sm: '4.188rem', xs: '3rem'},
                        fontWeight: 'bold',
                        marginBottom: '1.4rem',
                        textAlign: 'left',
                        maxWidth: {sm: '570px', xs: '100%'}
                    }}>
                    Inserisci le informazioni qui sotto per partecipare all'evento
                </Typography>
                <form onSubmit={sendData}>
                    <Stack spacing={2}>
                        {(scuole ? campiScuole : campiPersone).map((campo, index) => (
                            <TextField
                                key={index}
                                id={campo.id}
                                placeholder={campo.label}
                                type={campo.type}
                                name={campo.id}
                                variant={'outlined'}
                                fullWidth
                                required
                                onChange={handleCompileDati}
                            />
                        ))}
                    </Stack>

                    <Box sx={{textAlign:'center', pt:3}}>
                        {loading ? <CircularProgress/> : <Button variant={'contained'}
                                 type={'submit'}
                                 target={'_blank'}
                                 disabled={loading}
                                 sx={{
                                     mt: {xs: '1rem', sm: 0},
                                     height: {xs: '55px', sm: 'auto'},
                                     fontSize: {xs: '1.25rem', sm: 'auto'},
                                     borderRadius: '3rem',
                                     background: mondoviTheme.palette.accent.main
                                 }}
                        >
                            Entra
                        </Button>}
                    </Box>
                </form>
            </Box>
            :
                <Box>
                    <Typography
                        variant={"h3"}
                        maxWidth={'550px'}
                        sx={{
                            paddingTop: 4,
                            fontSize: {sm: '2.5rem', xs: '2rem'},
                            lineHeight: {sm: '4.188rem', xs: '3rem'},
                            fontWeight: 'bold',
                            marginBottom: '1.4rem',
                            textAlign: 'left',
                            maxWidth: {sm: '570px', xs: '250px'}
                        }}>
                        Clicca il pulsante qui sotto per accedere alla chiamata Zoom
                    </Typography>
                    <Button variant={'contained'}
                            href={data.join_url}
                            target={'_blank'}
                            sx={{
                                mt: {xs: '1rem', sm: 0},
                                height: {xs: '55px', sm: 'auto'},
                                fontSize: {xs: '1.25rem', sm: 'auto'},
                                borderRadius: '3rem'
                            }}
                    >
                        Vai alla chiamata Zoom
                    </Button>
                </Box>
            }
        </Box>
    )
}
