import React, {useEffect} from "react";
import {Box, Button, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import croce_foto from '../assets/images/croce_foto.png'
import croce from '../assets/images/croce_home/croce foto-01.png'
import blu from '../assets/images/croce_home/linea blu in alto-01.png'
import rosa from '../assets/images/croce_home/linea rosa in alto-01.png'
import verde from '../assets/images/croce_home/linea verde in alto-01.png'
import cover_bg from '../assets/images/sfondo_1.jpg'
import sfondoMobile from '../assets/images/sfondo_1_mobile.jpg'
import {mondoviTheme} from "../theme/mondoviTheme";
import logo_desktop from "../assets/images/logo_header.png";
import logo_mobile from "../assets/images/logo_mobile.png";
import {motion, useScroll, useTransform} from "framer-motion";

const MainSection = ({sectionRefs}) => {
    const matches = useMediaQuery(mondoviTheme.breakpoints.up("sm"));
    const xl = useMediaQuery(mondoviTheme.breakpoints.up("xl"));

    const {scrollYProgress} = useScroll();

    const first = useTransform(scrollYProgress, [0, 1], ["0%", "-50%"]);
    const second = useTransform(scrollYProgress, [0, 1], ["0%", "-60%"]);
    const third = useTransform(scrollYProgress, [0, 1], ["0%", "-80%"]);
    const fourth = useTransform(scrollYProgress, [0, 1], ["0%", "-160%"]);

    const imgs = [
        {img: blu, transform: first},
        {img: verde, transform: second},
        {img: rosa, transform: third},
        {img: croce, transform: fourth},
    ]

    return (
        <Box ref={sectionRefs[0]} id='home' position={"fixed"} top={0} left={0} right={0} zIndex={-1}>
            <motion.div style={{
                translateY: first,
                transform: `translateY(calc (${scrollYProgress} * 50px))`
            }}>
            <Stack pt={10} pb={8}
                   sx={{
                       width: '100%',
                       height: '100vh',
                       //background: 'linear-gradient(117deg, rgba(71,177,91,1) 0%, rgba(33,106,212,1) 45%, rgba(33,106,212,1) 50%, rgba(30,84,127,1) 100%)',
                       background: 'linear-gradient(129deg, rgba(71,177,91,1) 12%, rgba(33,106,212,1) 41%, rgba(33,106,212,1) 54%, rgba(30,84,127,1) 94%)',
                       position: 'relative',
                       backgroundImage: {
                           lg: `url(${cover_bg})`,
                           md: `url(${cover_bg})`,
                           sm: `url(${cover_bg})`,
                           xs: `url(${sfondoMobile})`
                       },
                       backgroundAttachment: 'fixed',
                       backgroundSize: 'cover'
                   }} alignItems={'center'} justifyContent={'center'}>
                {!matches && <Box position={"fixed"} top={20} right={-670} zIndex={0}>
                    <img style={{width: '40%', objectFit: 'cover', opacity: 0.8}}
                         src={croce_foto}/>
                </Box>}
                <Grid container px={{xs: 2, sm: 2, md: 12}} justifyContent={{xs: 'center', lg: 'right'}} zIndex={1}>
                    <Grid item xs={12} mb={8} display={matches ? 'none' : 'inherit'}>
                        <img style={{
                            maxWidth: matches ? '190px' : '150px'
                        }} src={matches ? logo_desktop : logo_mobile}/>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={6} xl={5}>
                        {matches ? <p style={{
                                paddingInline: 4,
                                fontSize: 'min(4.8vw, 1.485rem)',
                                //fontWeight: 'bold',
                                maxWidth: '670px',
                                marginTop: '1rem',
                                textAlign: 'left'
                            }}>
                                22 APRILE, DALLE 9.30 ALLE 12.30
                            </p>
                            : <><Typography variant={'h5'} style={{
                                paddingTop: 4,
                                fontSize: 'min(6.5vw, 1.685rem)',
                                maxWidth: '670px',
                                margin: '0',
                                textAlign: 'left'
                            }}>
                                22 APRILE
                            </Typography>
                                <Typography variant={'h5'} style={{
                                    fontSize: 'min(6.5vw, 1.685rem)',
                                    maxWidth: '670px',
                                    marginBottom: '2rem',
                                    textAlign: 'left'
                                }}>
                                    9.30 - 12.30
                                </Typography>
                            </>}
                        <Typography
                            variant={"h2"}
                            maxWidth={'550px'}
                            sx={{
                                fontSize: matches ? '3.5rem' : '3rem',
                                lineHeight: matches ? '4.188rem' : '3rem',
                                fontWeight: 'bold',
                                marginBottom: '1.4rem',
                                textAlign: 'left',
                                maxWidth: matches ? null : '250px'
                            }}>
                            Giovani e istituzioni in dialogo per il territorio
                        </Typography>
                        <p style={{
                            paddingInline: 4,
                            fontSize: 'min(4.8vw, 1.485rem)',
                            fontWeight: 400,
                            color: matches ? mondoviTheme.palette.text.secondary : mondoviTheme.palette.text.primary,
                            maxWidth: '670px',
                            marginTop: '1rem',
                            marginBottom: 0,
                            textAlign: 'left'
                        }}>
                            La prima agorà digitale promossa dal Comune di Mondovì.
                            </p>
                        <p style={{
                            paddingInline: 4,
                            fontSize: 'min(4.8vw, 1.485rem)',
                            fontWeight: 400,
                            color: matches ? mondoviTheme.palette.text.secondary : mondoviTheme.palette.text.primary,
                            maxWidth: '670px',
                            marginTop: 0,
                            textAlign: 'left'
                        }}>
                            150 partecipanti, 15 tavoli virtuali, 2 temi principali, un unico grande obiettivo:
                            immaginare un domani in cui le nuove generazioni saranno protagoniste.
                        </p>
                        {matches ? <Stack direction={'row'}>
                                <Button variant={'contained'} href='#iscriviti'>
                                    FACCIAMOLO!
                                </Button>
                                <Button sx={{color: mondoviTheme.palette.text.secondary}} href='#comeFunziona'>
                                    COME FUNZIONA
                                </Button>
                            </Stack>
                            : <Button variant={'contained'} href='#iscriviti'
                                      sx={{
                                          background: '#47b15b',
                                          mt: '8px',
                                          height: '65px',
                                          width: '70%',
                                          fontSize: '2.2rem',
                                          borderRadius: '3rem'
                                      }}
                            >
                                FACCIAMOLO!
                            </Button>}
                    </Grid>
                    <Grid item xs={6} zIndex={-1}>
                        <Box sx={{
                            display: {lg: 'flex', xs: 'none'},
                            position: {lg: 'absolute'},
                            right: {xl: '-9rem', lg: '-4rem'},
                            top: {lg: '7%'},
                        }}>
                            <Box position={'relative'}>
                                {imgs.map((obj, index) => (
                                    <motion.div key={index} style={{
                                        translateY: obj.transform,
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        transform: `translateY(calc (${scrollYProgress} * 50px))`
                                    }}>
                                        <img className={'el2'} src={obj.img} width={xl ? '850px' : '750px'}/>
                                    </motion.div>)
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
            </motion.div>
        </Box>
    )
}

export default MainSection
