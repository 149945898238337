import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/images/logo_header.png'
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import {Box, Button, Container, Divider, Stack} from "@mui/material";
import {Link} from "react-router-dom";
import {mondoviTheme} from "../theme/mondoviTheme";
import useScrollSpy from 'react-use-scrollspy';
import {useTranslation} from "react-i18next";

export function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger} timeout={500}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function Header(props) {

    const activeSection = useScrollSpy({
        sectionElementRefs: props.sectionRefs, // Array of References to DOM elements
        offsetPx: -80,
        throttleMs: 10
    });

    return (
        //<HideOnScroll {...props}>
            <AppBar>
                <Toolbar sx={{alignItems: 'flex-end'}}>
                    <Container maxWidth={"xxl"}>
                        <Stack paddingX={props.sectionRefs ? 4 : 0} direction={"row"}
                               justifyContent={"space-between"}
                               alignContent={"center"} alignItems={'center'}>
                            <Box mt={3} component={Link} to={'/'}>
                                <img style={{maxWidth:'170px', maxHeight:'65px'}} src={logo} />
                            </Box>
                            {
                                props.sectionRefs && <Stack direction={"row"}
                                    justifyContent={"space-between"}
                                    spacing={1}
                                    alignItems={"baseline"}>
                                <div>
                                    <Button variant={'menu'} href={'#home'} onClick={() => {
                                        window.scrollTo(0, 0)
                                    }}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize: '1.25rem',
                                            color: activeSection === 0 ? mondoviTheme.palette.primary.main : 'inherit'
                                        }}>HOME</p>
                                    </Button>
                                    {//location.hash.includes('clienti') &&
                                        activeSection === 0 &&
                                        <Divider color={mondoviTheme.palette.primary.main}
                                                 variant={'fullWidth'}/>}
                                </div>
                                <div>
                                    <Button variant={'menu'} href={'#comeFunziona'}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize: '1.25rem',
                                            color: activeSection === 1 ? mondoviTheme.palette.primary.main : 'inherit'
                                        }}>COME FUNZIONA</p>
                                    </Button>
                                    {//location.hash.includes('funzioni') &&
                                        activeSection === 1 && <Divider color={mondoviTheme.palette.primary.main}
                                                                        variant={'fullWidth'}/>}
                                </div>
                                <div>
                                    <Button variant={'menu'} href={'#tematiche'}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize: '1.25rem',
                                            color: activeSection === 2 ? mondoviTheme.palette.primary.main : 'inherit'
                                        }}>LE TEMATICHE</p>
                                    </Button>
                                    {//location.hash.includes('analytics') &&
                                        activeSection === 2 &&
                                        <Divider color={mondoviTheme.palette.primary.main}
                                                 variant={'fullWidth'}/>}
                                </div>
                                <div>
                                    <Button variant={'menu'} href={'#faq'}>
                                        <p style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontWeight: 400,
                                            fontSize: '1.25rem',
                                            color: activeSection === 3 ? mondoviTheme.palette.primary.main : 'inherit'
                                        }}>FAQ</p>
                                    </Button>
                                    {//location.hash.includes('analytics') &&
                                        activeSection === 3 &&
                                        <Divider color={mondoviTheme.palette.primary.main}
                                                 variant={'fullWidth'}/>}
                                </div>
                                <Button href={'#iscriviti'}
                                        sx={{
                                            display: {sm: 'none', md: 'inherit'},
                                            color: 'white',
                                        }}
                                        variant={activeSection === 4 ? "outlinedSelected" : "outlined"}
                                >
                                    ISCRIVITI
                                </Button>
                            </Stack>}
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
        //</HideOnScroll>
    );
}
