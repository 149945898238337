import React from "react";
import {Box, lighten, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {mondoviTheme} from "../theme/mondoviTheme";
import {useTranslation} from "react-i18next";
import privacyPolicy from '../assets/Informativa_domani+giovani_02102020.pdf'

export const Footer = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.down('lg'))
    const mobile = useMediaQuery(mondoviTheme.breakpoints.between("xs", "sm"));

    return (
        !mobile ? <Box p={8} sx={{
                background: mondoviTheme.palette.background.secondary,
                color: lighten(mondoviTheme.palette.background.default, 0.35)
            }}
                       display={'flex'}
                       justifyContent={'space-between'}
                       flexDirection={md ? 'column' : 'row'}
            >
                <Stack sx={{textAlign: 'center'}}
                       direction={md ? 'column' : 'row'}
                       alignItems={'center'}
                       spacing={4}
                >
                    <img style={{maxWidth: '12rem', width: '100%', objectFit: 'contain'}}
                         src={require('../assets/images/logo_header.png')}/>
                    <Stack alignItems={'start'}>
                        <Typography variant={'body1'} sx={{maxWidth: md ? '250px' : 'inherit'}}
                                    fontWeight={'bold'}
                                    color={'white'}
                        >
                            22 APRILE 2023
                        </Typography>
                        <Typography variant={'body1'} sx={{maxWidth: md ? '250px' : 'inherit'}}>
                            H 9:30 — 12:30
                        </Typography>
                    </Stack>
                    <img style={{maxWidth: '10rem', width: '100%', objectFit: 'contain'}}
                         src={require('../assets/loghi/logo mondovi.png')}/>
                    <Typography variant={'body1'} sx={{maxWidth: md ? '250px' : 'inherit'}}>
                        PROMOSSO DA
                    </Typography>
                    <img style={{maxWidth: '5rem', width: '100%', objectFit: 'contain'}}
                         src={require('../assets/loghi/logo_mondovi.png')}/>
                    <Typography variant={'body1'} sx={{maxWidth: md ? '250px' : 'inherit'}}>
                        CON IL CONTRIBUTO DI
                    </Typography>
                    <img style={{maxWidth: '15rem', width: '100%', objectFit: 'contain'}}
                         src={require('../assets/loghi/logo_crc.png')}/>
                </Stack>
                <Stack mt={md ? 2 : 'inherit'}
                       direction={md ? 'column' : 'row'}
                       justifyContent={"space-between"}
                       alignItems={'center'}
                       spacing={2}>
                    <Typography variant={'body2'}>
                        <a style={{color: 'inherit'}} href={privacyPolicy} target={'_blank'}>
                            Privacy
                        </a>
                    </Typography>
                    <Typography variant={'body2'}>
                        <a style={{color: 'inherit'}} href={'https://plesh.co/cookie-policy/'} target={'_blank'}>
                            Cookies
                        </a>
                    </Typography>
                </Stack>
            </Box>
            : <Box p={8} sx={{
                background: mondoviTheme.palette.background.secondary,
                color: lighten(mondoviTheme.palette.background.default, 0.6)
            }}
                   display={'flex'}
                   justifyContent={'space-between'}
                   flexDirection={"column"}
            >
                <Stack sx={{textAlign: 'center'}}
                       direction={"column"}
                       alignItems={'center'}
                       spacing={3}
                >
                    <Box><img style={{maxWidth: '10rem', width: '100%', objectFit: 'contain'}}
                              src={require('../assets/loghi/logo mondovi.png')}/></Box>
                    <Typography variant={'body1'} >
                        PROMOSSO DA
                    </Typography>
                    <img style={{maxWidth: '8rem', width: '100%', objectFit: 'contain'}}
                         src={require('../assets/loghi/logo_mondovi.png')}/>

                    <Typography variant={'body1'} sx={{paddingTop: 4}}>
                        CON IL CONTRIBUTO DI
                    </Typography>
                    <img style={{maxWidth: '15rem', width: '100%', objectFit: 'contain'}}
                         src={require('../assets/loghi/logo_crc.png')}/>

                    <img style={{maxWidth: '15rem', width: '100%', objectFit: 'contain', paddingTop: 22}}
                         src={require('../assets/images/logo_header.png')}/>
                    <Stack alignItems={'start'} pt={4}>
                        <Typography variant={'body1'}
                                    fontWeight={'bold'}
                                    color={'white'}
                                    fontSize={'1.5rem'}
                        >
                            22 APRILE 2023
                        </Typography>
                        <Typography variant={'body1'}
                                    fontSize={'1.5rem'}>
                            H 9:30 — 12:30
                        </Typography>
                    </Stack>
                </Stack>
                <Stack mt={4}
                       direction={"column"}
                       justifyContent={"space-between"}
                       alignItems={'center'}
                       spacing={2}>
                    <Typography variant={'body1'}>
                        <a style={{color: 'inherit'}} href={privacyPolicy} target={'_blank'}>
                            Privacy
                        </a>
                    </Typography>
                    <Typography variant={'body1'}>
                        <a style={{color: 'inherit'}} href={'https://plesh.co/cookie-policy/'} target={'_blank'}>
                            Cookies
                        </a>
                    </Typography>
                </Stack>
            </Box>

    )
}
